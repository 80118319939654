@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.container {
    flex: 1 1 auto;
    display: flex;

    background: url("../../../../assets/visuals/dashboard.jpg") no-repeat center center fixed;

    background-size: cover;
}

.content {
    display: flex;
    flex: 1 1 auto;

    flex-direction: column;

    padding: spacing(8, -4);
    background-color: rgba(0, 0, 0, 0.6);
}

.data {
    margin: auto 0;
}

.loader {
    margin: auto;
    color: $base-loader-color;
}

.text1 {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $base-text-color;
}

.text2 {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 1px;
    color: $base-text-color;
    text-transform: uppercase;
}

.text3 {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: $base-text-color;
}

.text4 {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 1px;
    color: $base-text-color;
    text-transform: uppercase;
}

.graph {
    margin-top: spacing(3, 0);
    margin-bottom: spacing(4, 0);
}

.logo {
    margin-top: spacing(0, 5);
}

.second {
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $base-text-color;

    margin-left: spacing(0, 5);
}

.pFirst {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $base-text-color;
    margin-right: spacing(0, 5);
}

.pSecond {
    @extend .second;
}

.map {
    margin-top: spacing(3, 0);
    margin-bottom: spacing(4, 0);

    svg {
        width: 100%;
        height: 100%;

        image {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.cFirst {
    font-size: 90px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.98;
    letter-spacing: normal;
    color: $base-text-color;
}

.cSecond {
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: $base-text-color;
}

.brand {
    @extend .second;
}

.rFirst {
    font-size: 54px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: $base-text-color;
}

.rSecond {
    @extend .second;
}

.bar {
    margin-top: spacing(2, -1);
}

.bmw {
    margin: auto;
}
.qr {
    margin: auto;
}

.dFirst {
    @extend .text4;
}

.dSecond {
    @extend .second;
    font-size: 12px;
}

.hFirst {
    @extend .text2;
}

.hSecond {
    @extend .second;
    font-size: 14px;
}

@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.container {
    flex: 1 1 auto;
    display: flex;

    background: url("../../../../assets/visuals/slideOne.jpeg") no-repeat center center fixed;

    background-size: cover;
}

.content {
    display: flex;
    flex: 1 1 auto;

    flex-direction: column;

    padding: spacing(8, -4);
    background-color: rgba(0, 0, 0, 0.3);
}

.row {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
}

.message {
    max-width: 754px;
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $base-text-color;

    margin-right: auto;
}

.loader {
    margin: auto;
    color: $base-loader-color;
}

.infoRow {
    align-items: flex-end;
}

.info {
    display: inline-block;
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

.first {
    font-size: 270px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $base-text-color;
}

.second {
    font-size: 120px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $base-text-color;
}

.cars {
    margin-left: spacing(2, 4);

    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $base-text-color;
}

@import 'src/styles/variables/layout';

.container {
    display: flex;
    flex-direction: column;

    flex: 0 0 auto;

    &.stretch {
        flex: 1;
    }
}

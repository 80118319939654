@import 'src/styles/variables/layout';
@import 'src/styles/variables/colors';

.container {
    flex: 1 1 auto;
    display: flex;

    background: url("../../../../../assets/visuals/dashboard.jpg") no-repeat center center fixed;

    background-size: cover;
}

.content {
    display: flex;
    flex: 1 1 auto;

    flex-direction: column;

    padding: spacing(8, -4);
    background-color: rgba(0, 0, 0, 0.7);
}

.row {
    display: flex;
    flex-direction: row;
    
    &.grow {
        flex: 1 1 auto;
    }
}

.message {
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $base-text-color;

    margin-right: auto;
}

.loader {
    margin: auto;
    color: $base-loader-color;
}
@import "./font-faces";
@import "./variables/fonts";
@import "./variables/colors";

body {
    overflow: overlay;
    scrollbar-gutter: overlay;
}

/* Works on Firefox */
* {
    scrollbar-width: none;
    scrollbar-color: transparent transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px;
}

.onScrollbar {
    scrollbar-width: thin !important;
    scrollbar-color: rgba($base-border-color, 0.5) transparent !important;
}

.onScrollbar::-webkit-scrollbar-thumb {
    background-color: rgba($base-border-color, 0.5) !important;
}

* {
    margin: 0;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
    box-sizing: inherit;
    word-break: break-word;
}

*:before,
*:after {
    box-sizing: inherit;
}

/*
 * Give element block that are not block by default.
 * This makes sure the parent div is the exact size of the element.
 */
label,
img,
audio,
video,
canvas,
meter,
progress,
svg,
iframe {
    display: block;
}

button {
    font-size: inherit;
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

ol,
ul {
    padding: 0;
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

html,
body,
#root {
    height: 100%;
    padding: 0;
}

#root > div {
    height: 100%;
}

body {
    font-family: $font-default;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

:root {
    --app-height: 100%;
}

/* ------------------------------------- */

$white: #ffffff;
$blue-light: #9adcff;
$grey: rgba(0, 0, 0, 0.6);

$base-background-color: $grey;
$base-text-color: $white;
$secondary-text-color: $grey;
$base-border-color: $grey;
$base-focus-color: $blue-light;
$base-placeholder-color: $grey;
$base-button-color: $blue-light;
$secondary-button-color: $white;
$base-loader-color: $blue-light;

@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

.container {
    display: flex;
    flex-direction: row;

    flex: 0 0 auto;

    &.stretch {
        flex: 1;
    }

    > * + * {
        padding-left: spacing(2, 4);
    }

    > *:not(:last-child) {
        padding-right: spacing(2, 4);
    }

    &.seporate {
        > *:not(:first-child) {
            border-left: 0.5px groove $white;
        }
    }

    &.aling {
        align-items: baseline;

        padding-top: spacing(0, 5);
        padding-bottom: spacing(0, 5);

        > * {
            padding-top: spacing(1, 2);
            padding-bottom: spacing(1, 2);
        }

        :first-child {
            width: 140px;
        }
    }

    &.flexEnd {
        align-items: flex-end;
    }

    &.buttom {
        border-bottom: 0.5px groove $white;
    }
}

.container {
    width: 92px;
    height: 92px;
    padding-top: 10px;

    &.isSmall {
        width: 82px;
        height: 10px;
    }
}

$font-location: "../assets/fonts";
$font-display: "swap";

@font-face {
    font-family: "BMWTypeNextTT";
    src: local("BMWTypeNextTT-Light"),
      url($font-location + "/BMWTypeNextTT/BMWTypeNextTT-Light.woff2") format("woff2"),
      url($font-location + "/BMWTypeNextTT/BMWTypeNextTT-Light.woff") format("woff"),
      url($font-location + "/BMWTypeNextTT/BMWTypeNextTT-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    font-display: $font-display;
  }
  @font-face {
    font-family: "BMWTypeNextTT";
    src: local("BMWTypeNextTT-Regular"),
      url($font-location + "/BMWTypeNextTT/BMWTypeNextTT-Regular.woff2") format("woff2"),
      url($font-location + "/BMWTypeNextTT/BMWTypeNextTT-Regular.woff") format("woff"),
      url($font-location + "/BMWTypeNextTT/BMWTypeNextTT-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    font-display: $font-display;
  }

  @font-face {
    font-family: "BMWTypeNextTT";
    src: local("BMWTypeNextTT-Bold"),
      url($font-location + "/BMWTypeNextTT/BMWTypeNextTT-Bold.woff2") format("woff2"),
      url($font-location + "/BMWTypeNextTT/BMWTypeNextTT-Bold.woff") format("woff"),
      url($font-location + "/BMWTypeNextTT/BMWTypeNextTT-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-display: $font-display;
  }

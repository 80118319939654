@import "src/styles/variables/layout";
@import "src/styles/variables/colors";

$padding: spacing(2, 4);

.container {
    &.addPaddings {
        padding: spacing(2, 4);

        .wrapper {
            min-height: calc(var(--app-height) - 2 * #{$padding});
        }
    }

    .wrapper {
        min-height: calc(var(--app-height));
    }

    &.showHeader {
        .wrapper {
            min-height: calc(var(--app-height) - #{$header-size});
        }

        &.addPaddings {
            .wrapper {
                min-height: calc(var(--app-height) - 2 * #{$padding} - #{$header-size});
            }
        }
    }

    &.showFooter {
        .wrapper {
            min-height: calc(var(--app-height) - #{$footer-size});

            &.addPaddings {
                min-height: calc(var(--app-height) - 2 * #{$padding} - #{$footer-size});
            }
        }
    }

    &.showBoth {
        .wrapper {
            min-height: calc(var(--app-height) - #{$header-size} - #{$footer-size});

            &.addPaddings {
                min-height: calc(var(--app-height) - 2 * #{$padding} - #{$header-size} - #{$footer-size});
            }
        }
    }

    background-color: $base-background-color;
}

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    margin: 0 auto;

    @include media-query(tablet) {
        max-width: 100%;
    }
}

.loader {
    margin: auto;
    color: $base-loader-color;
}
